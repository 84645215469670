function getDaySuffix(day) {
    if (day >= 11 && day <= 13) {
        return "th";
    } else {
        var lastDigit = day % 10;
        switch (lastDigit) {
            case 1: return "st";
            case 2: return "nd";
            case 3: return "rd";
            default: return "th";
        }
    }
}


function getPromoCodeEndDate(){
    // Get the current date
    const currentDate = new Date();

    // Set the date to the first day of the next month
    currentDate.setMonth(currentDate.getMonth() + 3, 1);

    // Subtract one day to get the last day of the current month
    currentDate.setDate(currentDate.getDate() - 1);

    // Get the day of the month (last day of the current month)
    const lastDayOfMonth = currentDate.getDate();
    const currentYear = currentDate.getFullYear()
    const currentMonth = currentDate.toLocaleString('en-US', { month: 'long' });
    const suffix = getDaySuffix(lastDayOfMonth)
    const freeTrialPeriodTime = `${lastDayOfMonth}${suffix} ${currentMonth} ${currentYear}`
    return  freeTrialPeriodTime
}

export default getPromoCodeEndDate